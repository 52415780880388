<template>
    <div class="resume-setting">
        <!-- <div class="resume-setting-tab">
            <div
                class="tab-item"
                :class="activeTab == 'resumeMerge' ? 'active' : ''"
                @click="switchTab('resumeMerge')"
            >
                简历合并
            </div>
            <div
                class="tab-item"
                :class="activeTab == 'resumeHide' ? 'active' : ''"
                @click="switchTab('resumeHide')"
            >
                简历隐藏管理
            </div>
            <div
                class="tab-item"
                :class="activeTab == 'resumeSetting' ? 'active' : ''"
                @click="switchTab('resumeSetting')"
            >
                简历设置
            </div>
            <div
                class="tab-item"
                :class="activeTab == 'forewarning' ? 'active' : ''"
                @click="switchTab('forewarning')"
            >
                简历更新预警
            </div>
        </div> -->

        <lbd-tabs :tabs="tabs" :defaultActive="defaultActive" @tab-click="handleTabClick">
        </lbd-tabs>
        <div class="main-c">
            <div v-show="activeTab == 'resumeMerge'" class="resume-merge">
                <div class="resume-merge-main">
                    <div class="link">
                        <router-link to="/resumeMergeLog">合并日志</router-link>
                    </div>
                    <h3 class="title"><span></span>要删除的简历</h3>
                    <el-input
                        class="input"
                        v-model="deleteId"
                        placeholder="请输入要删除的简历ID"
                    ></el-input>
                    <el-button
                        class="button"
                        type="primary"
                        @click="handleDelete"
                        v-loading="deleteBtnLoading"
                        >添加
                    </el-button>
                    <resume-merge-table
                        class="table"
                        :tableData="deleteData"
                        type="delete"
                        @removeRow="handleRemoveDeleteRow"
                    ></resume-merge-table>

                    <h3 class="title"><span></span>要留下的简历</h3>
                    <el-input
                        class="input"
                        v-model="keepId"
                        placeholder="请输入要留下的简历ID"
                    ></el-input>
                    <el-button
                        class="button"
                        type="primary"
                        @click="handleKeep"
                        v-loading="keepBtnLoading"
                        :disabled="keepBtnDisabled"
                        >确定
                    </el-button>
                    <resume-merge-table
                        class="table"
                        :tableData="keepData"
                        type="keep"
                        @removeRow="handleRemoveKeepRow"
                    ></resume-merge-table>
                </div>

                <div class="resume-merge-btn">
                    <el-button
                        class="btn"
                        type="primary"
                        @click="handleShowMergeDialog"
                        v-loading="mergeBtnLoading"
                        >合并
                    </el-button>
                    <span class="resume-merge-btn-tip">
                        合并后，将会把要删除的简历的【寻访、推荐记录、附件】合并到要留下的简历。请确认要删除的简历的归属权已经更改。
                    </span>
                </div>
                <div class="resume-merge-footer">猎必得 liebide.com</div>

                <el-dialog
                    custom-class="merge-dialog"
                    title="提示"
                    :visible="mergeConfirmDialogVisible"
                    width="480px"
                    :show-close="false"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                >
                    <span class="merge-dialog-text">
                        确定合并吗？请确认好要删除的简历和要留下的简历ID输入正确，请确认要删除的简历的归属权已经更改。</span
                    >
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="handleCancelMerge">取消</el-button>
                        <el-button type="primary" @click="handleMerge">确定</el-button>
                    </span>
                </el-dialog>
                <el-dialog
                    custom-class="merge-dialog"
                    title="提示"
                    :visible="mergeSuccessDialogVisible"
                    width="480px"
                    :show-close="false"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                >
                    <span class="merge-dialog-text">合并成功，预计将在10分钟内生效</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="handleCloseSuccessDialog">取消</el-button>
                        <el-button type="primary" @click="handleJumpSuccessDetail"
                            >去查看</el-button
                        >
                    </span>
                </el-dialog>
            </div>
            <div v-show="activeTab == 'resumeHide'" class="resume-hide">
                <resume-hide></resume-hide>
            </div>

            <div v-show="activeTab == 'resumeSetting'" v-loading="pageLoading" class="resume-merge">
                <div class="resume-merge-main">
                    <!-- <div class="link">
                    <router-link to="/resumeMergeLog">合并日志</router-link>
                </div> -->
                    <h3 class="title"><span></span>附件简历</h3>
                    <div class="setting-block">
                        <div class="resume-merge-main-block">
                            <span>简历的附件仅展示最近</span>
                            <span v-if="!isEditStatus" class="count">{{ viewMonth }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="viewMonth"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0为全部展示
                                </span>
                            </div>
                            <span>个月的附件内容</span>
                        </div>
                        <div class="resume-merge-main-block">
                            <span>候选人简历附件更新时间在</span>
                            <span v-if="!isEditStatus" class="count">{{ limitMonth }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="limitMonth"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0时，推荐候选人时必须上传附件
                                </span>
                            </div>
                            <span>个月内，推荐该候选人时无需上传推荐附件简历</span>
                        </div>
                    </div>

                    <h3 class="title"><span></span>简历信息编辑</h3>
                    <div class="setting-block">
                        <div class="resume-merge-main-block">
                            <span>简历联系电话修改频次：</span>
                            <span v-if="!isEditStatus" class="count">{{ mobileEditDays }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="mobileEditDays"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0为不限制
                                </span>
                            </div>
                            <span>天内，简历联系电话修改不得超过</span>
                            <span v-if="!isEditStatus" class="count">{{ mobileEditLimit }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="mobileEditLimit"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0为不限制
                                </span>
                            </div>
                            <span>次</span>
                        </div>

                        <div class="resume-merge-main-block">
                            <span>简历新姓名修改频次限定：</span>
                            <span v-if="!isEditStatus" class="count">
                                {{ resumeNameUpdateValidityDay }}
                            </span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="resumeNameUpdateValidityDay"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span
                                    ><font-icon href="#icon-information-circle"></font-icon
                                    >填写0为不限制</span
                                >
                            </div>
                            <span>天内，简历姓名修改不得超过</span>
                            <span v-if="!isEditStatus" class="count">{{
                                resumeNameUpdateCount
                            }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="resumeNameUpdateCount"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0为不限制
                                </span>
                            </div>
                            <span>次</span>
                        </div>

                        <div class="resume-merge-main-block">
                            <span>简历更新频次限定：</span>
                            <span v-if="!isEditStatus" class="count">
                                {{ resumeUpdateValidityDay }}
                            </span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="resumeUpdateValidityDay"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span
                                    ><font-icon href="#icon-information-circle"></font-icon
                                    >填写0为不限制</span
                                >
                            </div>
                            <span>天内，简历更新不得修改超过</span>
                            <span v-if="!isEditStatus" class="count">{{ resumeUpdateCount }}</span>
                            <div class="input-number-box" v-else>
                                <el-input-number
                                    size="small"
                                    v-model="resumeUpdateCount"
                                    :precision="0"
                                    :step="1"
                                    :max="99"
                                ></el-input-number>
                                <span>
                                    <font-icon href="#icon-information-circle"></font-icon>
                                    填写0为不限制
                                </span>
                            </div>
                            <span>次</span>
                        </div>
                    </div>

                    <h3 class="title"><span></span>简历候选人姓名无效词设置</h3>

                    <div class="setting-block">
                        <div class="tags-wrap" v-if="!isEditStatus">
                            <el-tag
                                :key="'tag_11_' + index"
                                v-for="(tag, index) in invalidWordsArr"
                                class="my-tag"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                        <div class="block-wp" v-else>
                            <div class="bw-top">
                                <el-tag
                                    :disable-transitions="false"
                                    class="my-tag"
                                    v-if="invalidWordsArr.length == 0"
                                >
                                    暂未设置姓名无效词
                                </el-tag>
                                <el-tag
                                    :key="'tag_1_' + index"
                                    v-for="(tag, index) in invalidWordsArr"
                                    closable
                                    :disable-transitions="false"
                                    @close="handleTagClose(tag, 'invalidWords')"
                                    class="my-tag"
                                >
                                    {{ tag }}
                                </el-tag>
                            </div>
                            <div class="bw-bottom">
                                <el-input
                                    type="textarea"
                                    class="tag-input"
                                    :autosize="{minRows: 4, maxRows: 7}"
                                    placeholder="请输入候选人姓名无效词，按回车键提交"
                                    v-model.trim="inputInvalidWordstValue"
                                    maxlength="40"
                                    show-word-limit
                                    @keyup.enter.native="handleInvalidWordsInputConfirm"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>

                    <h3 class="title"><span></span>候选人手机号黑名单</h3>
                    <div class="setting-block">
                        <div class="tags-wrap" v-if="!isEditStatus">
                            <el-tag
                                :key="'tag_21_' + index"
                                v-for="(tag, index) in blackListArr"
                                class="my-tag"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                        <div class="block-wp" v-else>
                            <div class="bw-top">
                                <el-tag
                                    :disable-transitions="false"
                                    class="my-tag"
                                    v-if="blackListArr.length == 0"
                                >
                                    暂未设置手机号黑名单
                                </el-tag>

                                <el-tag
                                    :key="'tag_2_' + index"
                                    v-for="(tag, index) in blackListArr"
                                    closable
                                    :disable-transitions="false"
                                    @close="handleTagClose(tag, 'blackList')"
                                    class="my-tag"
                                >
                                    {{ tag }}
                                </el-tag>
                            </div>
                            <div class="bw-bottom">
                                <el-input
                                    type="textarea"
                                    class="tag-input"
                                    :autosize="{minRows: 4, maxRows: 7}"
                                    placeholder="候选人手机号黑名单，按回车键提交"
                                    v-model.trim="inputBlackListValue"
                                    maxlength="40"
                                    show-word-limit
                                    @keyup.enter.native="handleBlackListInputConfirm"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>

                    <div class="resume-merge-main-btns">
                        <el-button v-if="isEditStatus" @click="handleCancelEditResumeSetting"
                            >取消
                        </el-button>
                        <el-button v-else @click="handleEditResumeSetting">编辑</el-button>
                        <el-button
                            v-if="isEditStatus"
                            type="primary"
                            @click="submitResumeSettings"
                            v-loading="resumeSaveBtnLoading"
                            >保存
                        </el-button>
                    </div>
                </div>
            </div>

            <div v-show="activeTab == 'forewarning'" class="fore-warning">
                <resume-update-fore-warning> </resume-update-fore-warning>
            </div>
        </div>
    </div>
</template>

<script>
import ResumeMergeTable from "./component/resumeMergeTable.vue";
import ResumeHide from "./layout/resumeHide.vue";
import ResumeUpdateForeWarning from "./layout/resume-update-fore-warning.vue";
import LbdTabs from "#/views/userPermissionSet/components/lbd-tabs.vue";
import resumeMergeService from "#/js/service/resumeMerge.js";
import {internationalMobile} from "#/js/util/validators.js";

export default {
    name: "resumeMerge",
    components: {
        ResumeMergeTable,
        ResumeHide,
        ResumeUpdateForeWarning,
        LbdTabs,
    },
    data() {
        return {
            tabs: [
                {label: "简历合并", name: "resumeMerge"},
                {label: "简历隐藏管理", name: "resumeHide"},
                {label: "简历设置", name: "resumeSetting"},
                {label: "简历更新预警", name: "forewarning"},
            ],
            defaultActive: 0,

            pageLoading: false,
            activeTab: "resumeMerge",
            deleteId: "",
            deleteBtnLoading: false,
            keepId: "",
            mergeResumeId: "",
            keepBtnLoading: false,
            deleteData: [],
            keepData: [],
            mergeBtnLoading: false,
            mergeConfirmDialogVisible: false,
            mergeSuccessDialogVisible: false,
            viewMonth: 12,
            originViewMonth: 12,
            limitMonth: 12,
            originLimitMonth: 12,
            mobileEditDays: 30,
            originMobileEditDays: 30,
            mobileEditLimit: 3,
            originMobileEditLimit: 3,
            resumeSaveBtnLoading: false,
            settingId: "",
            isEditStatus: false,

            resumeNameUpdateValidityDay: 30,
            originResumeNameUpdateValidityDay: 30,
            resumeNameUpdateCount: 3,
            originResumeNameUpdateCount: 3,

            resumeUpdateValidityDay: 30,
            originResumeUpdateValidityDay: 30,
            resumeUpdateCount: 3,
            originResumeUpdateCount: 3,

            originInvalidWords: "",
            originBlackList: "",

            invalidWordsArr: [], // 简历候选人姓名无效词
            inputInvalidWordstValue: "",
            blackListArr: [], // 简历候选人姓名黑名单
            inputBlackListValue: "",
        };
    },
    computed: {
        keepBtnDisabled() {
            return this.keepData.length >= 1;
        },
        isResumeSet() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes("ResumeSet");
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    mounted() {
        if (!this.$store.state.user.userInfo.isAdministrator && !this.isResumeSet) {
            shortTips("权限不足，请联系管理员 .");
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex((item) => item.path === "/resumeMerge");
            tabItems.splice(_index, 1);
            sessionStorage.setItem("tabItems", JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000);
        }

        if (!this.userInfo.isCFUser) {
            this.tabs = [
                {label: "简历设置", name: "resumeSetting"},
                {label: "简历更新预警", name: "forewarning"},
            ];
            this.activeTab = 'resumeSetting';

            this.getResumeSettings();
        }
    },
    methods: {
        switchTab(tab) {
            this.activeTab = tab;

            if (tab == "resumeSetting") {
                this.getResumeSettings();
            }
        },
        checkIfIdExists(id, list) {
            let result = false;
            list.some((item) => {
                if (item.tbdResumeId == id) {
                    result = true;
                    return true;
                }
            });
            return result;
        },
        handleDelete() {
            if (this.deleteBtnLoading) {
                return false;
            }
            if (!this.deleteId) {
                shortTips("请输入简历ID");
                return false;
            }
            if (this.checkIfIdExists(this.deleteId, this.deleteData)) {
                shortTips("列表中已存在，请勿重复添加！");
                return false;
            }
            this.deleteBtnLoading = true;
            resumeMergeService
                .getMergeView(this.deleteId)
                .then((res) => {
                    this.deleteData.push(res);
                    this.deleteId = "";
                })
                .finally(() => {
                    this.deleteBtnLoading = false;
                });
        },
        handleKeep() {
            if (this.keepBtnLoading) {
                return false;
            }
            if (!this.keepId) {
                shortTips("请输入简历ID");
                return false;
            }
            if (this.checkIfIdExists(this.keepId, this.keepData)) {
                shortTips("列表中已存在，请勿重复添加！");
                return false;
            }
            this.keepBtnLoading = true;
            resumeMergeService
                .getMergeView(this.keepId)
                .then((res) => {
                    this.keepData.push(res);
                    this.mergeResumeId = this.keepId;
                    this.keepId = "";
                })
                .finally(() => {
                    this.keepBtnLoading = false;
                });
        },
        handleShowMergeDialog() {
            if (this.deleteData.length == 0) {
                shortTips("删除简历Id不允许为空");
                return false;
            }

            if (this.mergeResumeId == "") {
                shortTips("保留简历Id不允许为空");
                return false;
            }

            this.mergeConfirmDialogVisible = true;
        },
        handleCancelMerge() {
            this.mergeConfirmDialogVisible = false;
        },
        handleMerge() {
            this.mergeConfirmDialogVisible = false;
            this.mergeBtnLoading = true;
            let recordId = _generateUUID();
            let deleteResumeIds = [];
            this.deleteData.forEach((item) => {
                deleteResumeIds.push(item.tbdResumeId);
            });
            resumeMergeService
                .doMerge({
                    recordId: recordId,
                    mergeResumeId: this.mergeResumeId,
                    deleteResumeIds: deleteResumeIds,
                })
                .then((res) => {
                    this.mergeSuccessDialogVisible = true;
                    this.deleteData = [];
                    this.keepData = [];
                })
                .finally(() => {
                    this.mergeBtnLoading = false;
                });
        },
        handleCloseSuccessDialog() {
            this.mergeSuccessDialogVisible = false;
        },
        handleJumpSuccessDetail() {
            this.mergeSuccessDialogVisible = false;
            if (this.mergeResumeId) {
                // window.open(`/Headhunting/MyCompany.html#/candidateDetail/${this.mergeResumeId}`)
                window.open(`/#/candidateDetail/${this.mergeResumeId}`);
            }
        },
        handleRemoveDeleteRow(tbdResumeId) {
            let removeIndex = -1;
            this.deleteData.some((item, index) => {
                if (item.tbdResumeId == tbdResumeId) {
                    removeIndex = index;
                    return true;
                }
            });
            if (removeIndex > -1) {
                this.deleteData.splice(removeIndex, 1);
            }
        },
        handleRemoveKeepRow(tbdResumeId) {
            if (this.keepData.length >= 1 && this.keepData[0].tbdResumeId == tbdResumeId) {
                this.keepData = [];
                this.mergeResumeId = "";
            }
        },
        getResumeSettings() {
            this.pageLoading = true;
            resumeMergeService
                .getResumeSettings()
                .then((res) => {
                    this.settingId = res.id || "";
                    this.viewMonth = res.attachmentVisibleMonth;
                    this.originViewMonth = res.attachmentVisibleMonth;
                    this.limitMonth = res.attachmentValidityMonth;
                    this.originLimitMonth = res.attachmentValidityMonth;
                    this.mobileEditDays = res.mobileUpdateValidityDay;
                    this.originMobileEditDays = res.mobileUpdateValidityDay;
                    this.mobileEditLimit = res.mobileUpdateCount;
                    this.originMobileEditLimit = res.mobileUpdateCount;

                    this.resumeNameUpdateCount = res.resumeNameUpdateCount;
                    this.resumeNameUpdateValidityDay = res.resumeNameUpdateValidityDay;
                    this.resumeUpdateCount = res.resumeUpdateCount;
                    this.resumeUpdateValidityDay = res.resumeUpdateValidityDay;
                    this.invalidWordsArr = res.invalidWords.split(",");
                    this.blackListArr = res.blackList.split(",");

                    this.originResumeNameUpdateCount = res.resumeNameUpdateCount;
                    this.originResumeNameUpdateValidityDay = res.resumeNameUpdateValidityDay;
                    this.originResumeUpdateCount = res.resumeUpdateCount;
                    this.originResumeUpdateValidityDay = res.resumeUpdateValidityDay;
                    this.originInvalidWords = res.invalidWords.split(",");
                    this.originBlackList = res.blackList.split(",");
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },
        submitResumeSettings() {
            if (this.resumeSaveBtnLoading) {
                return false;
            }

            this.pageLoading = true;
            this.resumeSaveBtnLoading = true;
            let params = {
                attachmentValidityMonth: this.limitMonth,
                attachmentVisibleMonth: this.viewMonth,
                mobileUpdateValidityDay: this.mobileEditDays,
                mobileUpdateCount: this.mobileEditLimit,

                resumeNameUpdateValidityDay: this.resumeNameUpdateValidityDay,
                resumeNameUpdateCount: this.resumeNameUpdateCount,
                resumeUpdateValidityDay: this.resumeUpdateValidityDay,
                resumeUpdateCount: this.resumeUpdateCount,
                invalidWords: this.invalidWordsArr.join(","),
                blackList: this.blackListArr.join(","),
            };
            if (this.settingId) {
                params.id = this.settingId;
            }
            resumeMergeService
                .saveResumeSettings(params)
                .then((res) => {
                    this.originViewMonth = this.viewMonth;
                    this.originLimitMonth = this.limitMonth;
                    this.originMobileEditDays = this.mobileEditDays;
                    this.originMobileEditLimit = this.mobileEditLimit;

                    this.originResumeNameUpdateValidityDay = this.resumeNameUpdateValidityDay;
                    this.originResumeNameUpdateCount = this.resumeNameUpdateCount;
                    this.originResumeUpdateValidityDay = this.resumeUpdateValidityDay;
                    this.originResumeUpdateCount = this.resumeUpdateCount;

                    this.originInvalidWords = this.invalidWordsArr;
                    this.originBlackList = this.blackListArr;

                    shortTips("保存成功！");
                    this.isEditStatus = false;
                })
                .finally(() => {
                    this.pageLoading = false;
                    this.resumeSaveBtnLoading = false;
                });
        },
        handleEditResumeSetting() {
            this.isEditStatus = true;
        },
        handleCancelEditResumeSetting() {
            this.viewMonth = this.originViewMonth;
            this.limitMonth = this.originLimitMonth;
            this.mobileEditDays = this.originMobileEditDays;
            this.mobileEditLimit = this.originMobileEditLimit;

            this.resumeNameUpdateValidityDay = this.originResumeNameUpdateValidityDay;
            this.resumeNameUpdateCount = this.originResumeNameUpdateCount;
            this.resumeUpdateValidityDay = this.originResumeUpdateValidityDay;
            this.resumeUpdateCount = this.originResumeUpdateCount;
            this.invalidWordsArr = this.originInvalidWords;
            this.blackListArr = this.originBlackList;
            this.isEditStatus = false;
        },

        handleTabClick(idx, tab) {
            const {name} = tab;
            this.defaultActive = idx;
            this.activeTab = name;
            this.switchTab(name);
        },
        handleTagClose(tag, type) {
            if (type === "invalidWords") {
                this.invalidWordsArr.splice(this.invalidWordsArr.indexOf(tag), 1);
            } else {
                this.blackListArr.splice(this.blackListArr.indexOf(tag), 1);
            }
        },

        handleInvalidWordsInputConfirm() {
            let inputValue = this.inputInvalidWordstValue;
            if (inputValue) {
                if (this.invalidWordsArr.includes(inputValue)) {
                    return this.$message.error("请勿重复添加");
                }
                this.invalidWordsArr.push(inputValue);
            }
            this.inputInvalidWordstValue = "";
        },

        handleBlackListInputConfirm() {
            let inputValue = this.inputBlackListValue;
            if (inputValue) {
                if (_isDangerousString(inputValue)) {
                    return this.$message.error("您输入的内容包含非法字符，请重新输入");
                }
                if (!internationalMobile(inputValue)) {
                    return this.$message.error("手机号格式不正确，请重新输入");
                }
                if (this.blackListArr.includes(inputValue)) {
                    return this.$message.error("请勿重复添加");
                }
                this.blackListArr.push(inputValue);
            }
            this.inputBlackListValue = "";
        },
    },
};
</script>

<style lang="scss" scoped>
.resume-setting {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    margin: 20px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;

    &-tab {
        display: flex;
        padding: 10px 10px 20px;
        margin: 40px 30px 30px;
        background-color: #f5f5f5;
        justify-content: space-between;

        .tab-item {
            flex: 1;
            flex-grow: 1;
            max-width: 22%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 24px;
            color: #666;
            font-weight: bold;
            background-color: #fff;
            cursor: pointer;

            // &:first-child {
            //     margin-right: 40px;
            // }

            // &:nth-child(2) {
            //     margin: 0 40px;
            // }

            &.active {
                color: $primary;
            }
        }
    }

    .main-c {
        height: calc(100% - 54px);
        flex: 1;
        overflow-y: auto;
    }
}
.resume-merge {
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1;
    padding: 30px 20px;
    // margin: 0 30px;
    // height: 100%;
    // background: url("~@src/assets/images/home/home_bg.png") bottom center no-repeat;
    // background-color: #f5f5f5;

    &-main {
        position: relative;
        flex: 1;
        flex-grow: 1;

        .link {
            position: absolute;
            top: 20px;
            right: 0;
            font-size: 14px;
            color: #666;

            a:hover {
                color: $primary;
            }
        }

        .setting-block {
            background-color: #fff;
            border-radius: 10px;
            padding: 10px 20px;
            .tags-wrap {
                .my-tag {
                    margin-right: 16px;
                    // margin-bottom: 8px;
                    background: white;
                    border: 1px solid #ccc;
                    color: #666;
                    font-size: 14px;
                    ::v-deep .el-tag__close {
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        font-size: 12px;
                        color: #ccc;
                        text-align: center;
                        &:hover {
                            // background: #ccc;
                            color: white;
                        }
                    }
                }
            }
            .block-wp {
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 12px;
                .bw-top {
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 11px;
                    box-sizing: border-box;
                    .my-tag {
                        margin-right: 16px;
                        // margin-bottom: 8px;
                        background: white;
                        border: 1px solid #ccc;
                        color: #666;
                        font-size: 14px;
                        ::v-deep .el-tag__close {
                            width: 16px;
                            height: 16px;
                            line-height: 16px;
                            font-size: 12px;
                            color: #ccc;
                            text-align: center;
                            &:hover {
                                // background: #ccc;
                                color: white;
                            }
                        }
                    }
                }
                .bw-bottom {
                    padding-top: 12px;
                    .tag-input ::v-deep .el-textarea__inner {
                        border: 0;
                        padding-left: 0;
                    }
                }
            }
        }

        &-block {
            line-height: 60px;

            .count {
                margin: 0 6px;
                color: $primary;
            }

            /deep/ .el-input-number {
                margin: 0 10px;
            }

            .input-number-box {
                display: inline-block;
                position: relative;

                > span {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 50px;
                    left: 10px;
                    line-height: initial;
                    font-size: 12px;
                    color: #f56c6c;
                    white-space: nowrap;

                    .font-icon {
                        margin-right: 4px;
                    }
                }
            }
        }

        &-btns {
            margin-top: 20px;
        }
    }

    .title {
        padding-top: 20px;
        padding-bottom: 14px;
        margin: 0;
        font-size: 18px;
        color: #333;
        line-height: 26px;

        span {
            display: inline-block;
            width: 6px;
            height: 26px;
            background-color: $primary;
            margin-right: 10px;
            vertical-align: top;
        }
    }

    .input {
        display: inline-block;
        width: 575px;
        height: 40px;
        line-height: 40px;
        margin-right: 20px;
        margin-bottom: 17px;
    }

    .button {
        width: 102px;
        height: 40px;
        line-height: 40px;
        color: $primary;
        border: 1px solid $primary;
        background-color: #f5f5f5;
    }

    .table {
        margin-bottom: 20px;
    }

    &-btn {
        height: 42px;
        margin-bottom: 10px;

        .btn {
            width: 120px;
            height: 42px;
            line-height: 42px;
            margin-right: 14px;
        }

        &-tip {
            font-size: 14px;
            color: #666;
            vertical-align: bottom;
        }
    }

    &-footer {
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 12px;
        color: #999999;
    }

    /deep/ .merge-dialog {
        .el-dialog__header {
            display: none;
        }

        &-text {
            display: inline-block;
            padding: 20px 8px 25px 32px;
            font-size: 16px;
            color: #444;
        }
    }
}

.fore-warning {
    padding: 20px 40px;
    box-sizing: border-box;
}
</style>
